<template>
  <div class="d-flex flex-row align-items-center">
    <CContainer>
      <p
        style="
          text-align: center;
          font-size: 50px;
          color: #f3664a;
          font-weight: bold;
          white-space: pre-line;
        "
      >
        {{
          this.bot_id
            ? 'Step 1: Update Chatbot ' + this.chatbot_name
            : 'Create Chatbot Step 1: \n Select your Chatbot Source Data'
        }}
      </p>
      <!-- <CRow class="justify-content-center">
        <CCol :md="2" :lg="7" :xl="6">
          <CCard class="mx-4">
            <CCardBody class="p-4">
              <h1 class="text-center mb-4"></h1>
              <CRow>
                <CCol class="text-center">
                  <CButton
                    color="primary"
                    class="py-4 px-5 mx-3"
                    @click="goToStep2('web')"
                  >
                    <CIcon name="cil-browser" size="lg" class="mb-2" />
                    <div>Web</div>
                  </CButton>
                  <CButton
                    color="primary"
                    class="py-4 px-5 mx-3"
                    @click="goToStep2('text')"
                  >
                    <CIcon name="cil-align-left" size="lg" class="mb-2" />
                    <div>Text</div>
                  </CButton>
                  <CButton
                    color="primary"
                    class="py-4 px-5 mx-3"
                    @click="goToStep2('pdf')"
                  >
                    <CIcon name="cil-file" size="lg" class="mb-2" />
                    <div>PDF</div>
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow> -->

      <CCard class="selection">
        <!-- <CCardHeader>
          <h2 align="center">Source</h2>
        </CCardHeader> -->
        <CRow>
          <CCol>
            <CButton @click="goToStep2('web')" size="lg">
              <CCard :class="[maxHeightCard === 'web' ? 'h-100' : '']">
                <div><span></span></div>
                <CCardTitle align="center">Web Page</CCardTitle>
                <div class="text-center">
                  <img
                    src="../../public/images/browser.gif"
                    width="112"
                    height="112"
                  />
                </div>
                <CCardBody align="left" style="--card-body-height: 250px">
                  Select this option if you want to make a chatbot from a
                  website/page. This option allows you to get all the
                  information you need from your website and make a chatbot from
                  it. Note that you will need to turn off any anti-scraping
                  rules for the website scraper to work properly.
                </CCardBody>
              </CCard>
            </CButton>
          </CCol>
          <CCol>
            <CButton @click="goToStep2('file')" size="lg">
              <CCard :class="[maxHeightCard === 'file' ? 'h-100' : '']">
                <CCardTitle align="center">Files</CCardTitle>
                <div class="text-center">
                  <img
                    src="../../public/images/document.gif"
                    width="112"
                    height="112"
                  />
                </div>

                <CCardBody align="left" style="--card-body-height: 120px">
                  Select this option to create chatbots from uploaded files. The
                  following are files supported:
                  <div class="two-column-list">
                    <ul>
                      <li>Word (.docx)</li>
                      <li>Powerpoint (.pptx)</li>
                      <li>Excel (.xlsx)</li>
                      <li>HTML (.html)</li>
                      <li>Emails (.msg)</li>
                      <li>Images (.png, .svg)</li>
                    </ul>
                    <ul>
                      <li>Text (.txt)</li>
                      <li>CSV (.csv)</li>
                      <li>PDF (.pdf)</li>
                      <li>Markdown (.md)</li>
                      <li>JPG (.jpg, .jpeg)</li>
                    </ul>
                  </div>
                </CCardBody>
              </CCard>
            </CButton>
          </CCol>
          <CCol>
            <CButton @click="goToStep2('audio')" size="lg">
              <CCard :class="[maxHeightCard === 'audio' ? 'h-100' : '']">
                <CCardTitle align="center">Youtube/Podcast/Audio</CCardTitle>

                <div class="text-center">
                  <img
                    src="../../public/images/video-channel.gif"
                    width="112"
                    height="112"
                  />
                </div>

                <CCardBody class="left" style="--card-body-height: 120px">
                  Make Chatbots from your favorite Youtube and or Podcasts:
                  <br />
                  <br />
                  <ul align="left">
                    <li>Youtube urls and playlists</li>
                    <li>Apple Audio Codec (.m4a)</li>
                    <li>MPEG Audio Layer 3 (.mp3)</li>
                    <li>WebM Media (.webm)</li>
                    <li>MPEG-4 (.mp4)</li>
                    <li>MPGA Audio (.mpga)</li>
                    <li>WAV Format (.wav)</li>
                    <li>MPEG (.mpeg)</li>
                    <li>MOV QuickTime (.mov)</li>
                    <li>FLAC (.flac)</li>
                  </ul>
                </CCardBody>
              </CCard>
            </CButton>
          </CCol>
        </CRow>
      </CCard>
    </CContainer>
  </div>
</template>

<script>
import { CCard, CCardBody, CContainer, CRow, CCol, CButton } from '@coreui/vue'
// import { CIcon } from '@coreui/icons-vue'
import HTTPService from '@/services/HTTPService'
export default {
  name: 'ChatbotWizardPage1',
  components: {
    CCard,
    CCardBody,
    CContainer,
    CRow,
    CCol,
    CButton,
    // CIcon,
  },
  props: {
    bot_id: {
      type: Number,
      required: false,
    },
    chatbot_name: {
      type: String,
      required: false,
    },
  },
  mounted() {
    HTTPService.getuser()
      .then((response) => {
        ////console.log(response.data.user)
        this.user = response.data.user
      })
      .catch((error) => {
        console.error(error)
        this.$emit('change-active-component', {
          component: 'Login',
        })
      })
  },
  methods: {
    goToStep2(stepType) {
      //console.log('teststep ' + stepType)
      //console.log('this.bot_id ' + this.bot_id)

      this.$emit('change-active-component', {
        component: 'ChatbotStep2',
        params: {
          botType: stepType,
          bot_id: this.bot_id,
          chatbot_name: this.chatbot_name,
        },
      })
    },
  },
}
</script>

<style>
h1 {
  font-size: 2.5rem;
  font-weight: 500;
}

button {
  font-size: 1.3rem;
}

@media (max-width: 576px) {
  h1 {
    font-size: 2rem;
  }

  button {
    font-size: 1rem;
  }
}

.selection .h-100:first-child .card-body {
  height: 270px;
}

.selection .h-100:not(:first-child) .card-body {
  padding-bottom: calc(20px + (270px - var(--card-body-height)));
}

.two-column-list {
  display: flex;
  justify-content: center;
  margin-left: 10px;
}

.two-column-list ul {
  list-style-type: disc; /* Add this line to display bullet points */
  padding-left: 0;
  text-align: left; /* Center the text within each list item */
}

.two-column-list ul:first-child {
  margin-right: 100px; /* Add right margin to create padding between columns */
}
</style>
