<template>
  <div class="container-fluid">
    <CJumbotron>
      <div class="text-center">
        <h1 class="display-4">Turn anything into an AI Chatbot in minutes:</h1>
      </div>
      <p
        style="
          text-align: center;
          font-size: 40px;
          color: #f3664a;
          font-weight: bold;
        "
      >
        Create your own ChatGPT chatbot and install it on your website for just
        $15/month!
      </p>
      <p style="text-align: center; font-size: 20px">
        Its easy as 1,2,3! Use our Chatbot Wizard to setup and create your
        Chatbot by uploading files or submitting a website url, a Chatbot will
        be built for you that can answer any questions about your data.
      </p>
    </CJumbotron>
    <CCard class="faq">
      <CCardHeader>
        <h2 align="center">A Chatbot in 1 2 3!</h2>
      </CCardHeader>
      <CRow>
        <CCol>
          <CCard class="h-100">
            <div><span></span></div>
            <CCardTitle align="center" style="margin-top: 10px"
              >Select Content</CCardTitle
            >
            <div class="text-center">
              <img
                src="../../public/images/upload.gif"
                width="200"
                height="200"
              />
            </div>
            <CCardBody class="text-center">
              <p>
                Select to upload documents or enter a URL to train your AI
                chatbot. Files supported:
              </p>
              <div class="two-column-list">
                <ul>
                  <li>Word (.docx)</li>
                  <li>Powerpoint (.pptx)</li>
                  <li>Excel (.xlsx)</li>
                  <li>HTML (.html)</li>
                  <li>Emails (.msg)</li>
                  <li>Images (.png, .svg)</li>
                </ul>
                <ul>
                  <li>Text (.txt)</li>
                  <li>CSV (.csv)</li>
                  <li>PDF (.pdf)</li>
                  <li>Markdown (.md)</li>
                  <li>JPG (.jpg, .jpeg)</li>
                  <li>JPG (.jpg, .jpeg)</li>
                </ul>
              </div>
              <br />

              Various Audio and Youtube formats like mp4a, mp3, webm, mp4, mpga,
              wav, mpeg, mov, flac etc.

              <p><Strong style="align: left">Coming soon: </Strong></p>
              <div class="two-column-list" style="text-align: left">
                <ul>
                  <li>AWS S3 Directory</li>
                  <li>AWS S3 FileC</li>
                  <li>Google Drive</li>
                </ul>
                <ul>
                  <li>Google Cloud Directory</li>
                  <li>Google Cloud File</li>
                  <li>Twitter Feed</li>
                </ul>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard class="h-100">
            <CCardTitle align="center" style="margin-top: 10px"
              >Data Organized</CCardTitle
            >
            <div class="text-center">
              <img
                src="../../public/images/infographic.gif"
                width="200"
                height="200"
              />
            </div>

            <CCardBody align="center">
              Securely Store and Index your Text on our Encrypted Servers.
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard class="h-100">
            <CCardTitle align="center" style="margin-top: 10px"
              >Use your chatbot</CCardTitle
            >

            <div class="text-center">
              <img
                src="../../public/images/chat.gif"
                width="200"
                height="200"
              />
            </div>

            <CCardBody class="text-center">
              <div>Use your chat bot anyway you like:</div>
              <div align="right">
                <ul style="display: inline-block; text-align: left">
                  <li>Personal assistant</li>
                  <li>Embed on your website as a support chatbot</li>
                  <li>API for Chatbot</li>
                </ul>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCard>

    <main>
      <component :is="activeComponent"></component>
    </main>

    <div class="assistant-container">
      <div>
        <div class="assistant-icon" v-if="!isExpanded" @click="expandAssistant">
          <img
            src="../../public/images/speech-bubble2.gif"
            alt="Assistant icon"
            width="70"
            height="70"
          />
        </div>

        <!-- <div class="assistant-frame" v-if="isExpanded"> -->
        <!-- <div class="assistant-header">
          <h1>Assistant</h1>
          <button @click="collapseAssistant">Close</button>
        </div> -->
        <iframe
          v-if="isExpanded"
          class="assistant-iframe"
          src="https://chatbotanything.com/chatbot/14ed7ce6-f406-4253-92df-5a4c7c4f702c"
          frameborder="0"
          :style="{
            width: isExpanded ? '400px' : '50px',
            height: isExpanded ? '580px' : '50px',
            marginBottom: '10px',
          }"
        ></iframe>
      </div>
      <div
        class="assistant-icon"
        v-if="isExpanded"
        @click="expandAssistant"
        style="transform: rotate(270deg); margin-top: 20px"
      >
        <img
          src="../../public/images/minimize.gif"
          alt="Assistant icon"
          width="40"
          height="40"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { CCardTitle } from '@coreui/vue'
import FAQ from './FAQ.vue'
export default {
  name: 'HomePage',
  data() {
    return { isExpanded: false, activeComponent: FAQ }
  },
  components: { CCardTitle, FAQ },
  methods: {
    expandAssistant() {
      this.isExpanded = !this.isExpanded
    },
    collapseAssistant() {
      this.isExpanded = false
    },
    onTerms(event) {
      console.log(event)
      this.$emit('change-active-component', {
        component: 'Terms',
      })
    },
    onPrivacy(event) {
      console.log(event)
      this.$emit('change-active-component', {
        component: 'Privacy',
      })
    },
  },
}
</script>
<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 50px;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: 700;
  font-size: 22px;
}

.card-body {
  font-size: 18px;
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

h1,
h2,
h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

.container-fluid {
  padding: 30px;
}

.assistant-container {
  position: fixed;
  bottom: 50px;
  right: 50px; /* Add this line */
}

.assistant-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  bottom: 20px; /* adjust as desired */
  right: 0px; /* adjust as desired */
}

.assistant-frame {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 580px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: 80px;
}

.assistant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.assistant-header h1 {
  margin: 0;
}

.assistant-header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.assistant-iframe {
  flex: 1;
  border: none;
}

.footer {
  background-color: #f8f9fa;
  padding: 20px;
  margin-top: 50px;
}

.footer img {
  margin-right: 20px;
}

.footer-links {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.footer-links li {
  margin-right: 20px;
}

@media only screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }
  .footer img {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-links {
    justify-content: center;
  }
  .footer-links li {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.two-column-list {
  display: flex;
  justify-content: center;
}

.two-column-list ul {
  list-style-type: disc; /* Add this line to display bullet points */
  padding-left: 0;
  text-align: left; /* Center the text within each list item */
}

.two-column-list ul:first-child {
  margin-right: 100px; /* Add right margin to create padding between columns */
}
</style>
