<template>
  <div align="center">
    <p
      style="
        text-align: center;
        font-size: 50px;
        color: #f3664a;
        font-weight: bold;
      "
    >
      Subscription Successful!
    </p>
    <!-- <p>Session ID: {{ sessionId }}</p>
    <p>Subscription Type: {{ subscriptionType }}</p> -->
    <div class="card" style="width: 40%" align="left">
      <div class="card-header">Plan Name: {{ plan.name }}</div>
      <div class="card-body">
        <p class="card-text">Price: {{ plan.price }}{{ periodLabel }}</p>
        <p class="card-text">Chatbots: {{ plan.chatbots }}</p>
        <p class="card-text">Messages: {{ plan.messages }}</p>
        <p class="card-text">Characters: {{ plan.characters }}</p>
        <p class="card-text">Features: {{ plan.features }}</p>
      </div>
    </div>
    <div style="margin-top: 20px">
      <router-link to="/chatbotmain" class="btn btn-primary">
        Home
      </router-link>
    </div>
  </div>
</template>
<script>
import HTTPService from '@/services/HTTPService'

export default {
  name: 'SubscriptionSuccess',
  data() {
    return {
      sessionId: '',
      plan: {},
      periodLabel: '',
    }
  },
  async created() {
    const sessionId = this.$route.query.session_id
    ////console.log(sessionId)

    const subscribeSuccessResponse = await HTTPService.subscribesuccess(
      sessionId,
    )

    //console.log(subscribeSuccessResponse)

    this.sessionId = sessionId
    this.plan = subscribeSuccessResponse.data.plan

    if (this.plan.period === 'M') {
      this.periodLabel = '/month'
    } else if (this.plan.name === 'Free') {
      this.periodLabel = '/day'
    }
  },
}
</script>
