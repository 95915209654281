<template>
  <p
    style="
      text-align: center;
      font-size: 50px;
      color: #f3664a;
      font-weight: bold;
    "
  >
    Your Profile
  </p>
  <CContainer class="mt-4">
    <CRow class="justify-content-center">
      <CCol md="6">
        <CCard class="mx-4">
          <CCardHeader>
            <h1>Your Profile</h1>
          </CCardHeader>
          <CCardBody class="p-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">First Name:</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="user.firstname"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Last Name:</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="user.lastname"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Email:</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="user.email"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Plan:</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="user.plan"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row" align="right">
              <div class="col-sm-4"></div>
              <div class="col-sm-8">
                <button
                  class="btn btn-danger"
                  @click="logoff"
                  style="width: 150px"
                >
                  Logoff
                </button>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
  <CRow></CRow>

  <div class="mt-4">
    <component :is="active"></component>
  </div>
</template>
<script>
import HTTPService from '@/services/HTTPService'
import StorageService from '@/services/StorageService'
import ChangePassword from '@/views/ChangePassword.vue'

export default {
  name: 'ProfilePage',
  components: {
    ChangePassword,
  },
  data() {
    return {
      user: {},
      active: ChangePassword,
    }
  },
  mounted() {
    HTTPService.getuser()
      .then((response) => {
        ////console.log(response.data.user)
        this.user = response.data.user
      })
      .catch((error) => {
        console.error(error)
        this.$emit('change-active-component', {
          component: 'Login',
        })
      })
  },
  methods: {
    logoff() {
      StorageService.clearToken()
      this.$emit('change-active-component', {
        component: 'ChatbotHome',
      })
    },
  },
}
</script>
<style>
.mt-4 {
  margin-top: 1rem;
}
</style>
