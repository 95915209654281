<template>
  <div class="chatbot">
    <h1>{{ name }}</h1>
    <div class="chatbot-container">
      <div class="chatbot-header" :style="getHeadingStyle">
        <div v-if="getImage" class="image-container">
          <span>
            <img :src="getImage" alt="chatbot-image" class="centered-image" />
          </span>
        </div>

        <div>
          <h2 class="chatbot-heading">
            {{ getHeading }}
          </h2>
          <p>{{ getSubHeading }}</p>
        </div>
      </div>

      <div class="chatbot-conversation" ref="chatbotConversation">
        <div
          class="chatbot-message"
          v-for="(message, index) in messages"
          :key="index"
          :class="{
            'user-message': message.is_user,
            'bot-message': !message.is_user,
          }"
        >
          <div v-if="message.is_user" :style="getUserMessageStyle">
            <strong>You:</strong> {{ message.text }}
          </div>

          <!-- <div
            v-else
            :style="getBotMessageStyle"
            class="prose prose-sm prose-chatgpt break-words max-w-6xl"
            v-html="parseMarkdown(message.text)"
          /> -->
          <div
            v-else
            :style="getBotMessageStyle"
            v-html="
              '<strong>' +
              getAssistantName +
              ':</strong>' +
              parseMarkdown(message.text)
            "
          ></div>
        </div>
      </div>
      <div class="chatbot-input">
        <input
          type="text"
          v-model="userInput"
          @keydown.enter="sendMessage"
          :placeholder="getInputMessage"
        />
        <CButton
          @click="sendMessage"
          class="d-flex justify-content-center"
          :style="getSendButtonStyle"
        >
          <CIcon :icon="cilSend" />
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService.js'
import { v4 as uuidv4 } from 'uuid'
import { cilSend } from '@coreui/icons'
import { marked } from 'marked'
import DOMPurify from 'isomorphic-dompurify'
import hljs from 'highlight.js'

export default {
  props: {
    bot_id: {
      type: String,
      required: true,
    },
    conversation_id: {
      type: String,
      required: false,
    },
    style: {
      type: Object,
      default: () => ({
        botBackgroundColor: '#dcdcdc',
        botTextColor: 'black',
        chatbotHeading: 'Hello!',
        chatbotSubHeading: 'Ask me anything',
        firstMessage: 'Hello, what can I help you with?',
        headerBackgroundColor: '#f3877e',
        headerTextColor: '#FFFFFF',
        image: null,
        inputPlaceholderText: 'enter your question here..',
        sendButtonBackgroundColor: '#f3877e',
        userBackgroundColor: '#f3877e',
        userTextColor: '#FFFFFF',
        assistantName: 'Assistant',
      }),
    },
  },
  data() {
    return {
      messages: [],
      userInput: '',
      messageIndex: 0,
      conversationId: '',
      cilSend,
      localstyle: {},
    }
  },
  async mounted() {
    console.log('loading..' + this.bot_id)

    marked.setOptions({
      silent: true,
      xhtml: true,
      breaks: true,
      gfm: true,
    })

    const renderer = {
      code(code, lang) {
        let language = 'plaintext'
        let highlightedCode
        try {
          highlightedCode = hljs.highlightAuto(code).value
        } catch {
          language = hljs.getLanguage(lang) ? lang : 'plaintext'
          highlightedCode = hljs.highlight(code, { language }).value
        }

        // ... handle the highlighted code as needed

        // For example, you can append it to an element or return it as a string
        // For appending to an element:
        const container = document.createElement('div')
        container.innerHTML = highlightedCode
        document.body.appendChild(container)

        // For returning as a string:
        return highlightedCode
      },
    }

    marked.use({ renderer })

    if (this.bot_id !== 'preview') {
      try {
        const response = await HTTPService.get_style(this.bot_id)
        // console.log('finished loading bot settings')
        // console.log(response)
        this.localstyle = response.data
      } catch (error) {
        console.error(error)
        // if there is an error default
        this.localstyle = this.style
      }
    }

    if (this.bot_id === 'preview') {
      this.messages.push({
        text: this.style.firstMessage,
        is_user: false,
      })
      this.messageIndex = 1

      this.messages.push({
        text: 'what is this',
        is_user: true,
      })
      this.messageIndex = 2
    } else {
      this.messages.push({
        text: this.localstyle.firstMessage,
        is_user: false,
      })
      this.messageIndex = 1
    }
    if (this.conversation_id != '') {
      this.conversationId = this.conversation_id
    }

    if (this.$route.query.conversationId) {
      this.conversationId = this.$route.query.conversationId
    }

    if (!this.conversationId) {
      this.conversationId = uuidv4()
    } else {
      this.startConversation()
    }
  },
  computed: {
    getImage() {
      if (this.bot_id == 'preview') {
        return this.style.image
      } else {
        return this.localstyle.image
      }
    },

    getAssistantName() {
      if (this.bot_id == 'preview') {
        return this.style.assistantName
      } else {
        return this.localstyle.assistantName
      }
    },
    getHeading() {
      if (this.bot_id == 'preview') {
        return this.style.chatbotHeading
      } else {
        return this.localstyle.chatbotHeading
      }
    },
    getSubHeading() {
      if (this.bot_id == 'preview') {
        return this.style.chatbotSubHeading
      } else {
        return this.localstyle.chatbotSubHeading
      }
    },
    getHeadingStyle() {
      // console.log(
      //   'this is headerBackgroundColor' + this.style.headerBackgroundColor,
      // )
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.headerBackgroundColor,
          color: this.style.headerTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.headerBackgroundColor,
          color: this.localstyle.headerTextColor,
        }
      }
    },
    getSendButtonStyle() {
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.sendButtonBackgroundColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.sendButtonBackgroundColor,
        }
      }
    },
    getInputMessage() {
      if (this.bot_id == 'preview') {
        return this.style.inputPlaceholderText
      } else {
        return this.localstyle.inputPlaceholderText
      }
    },
    getUserMessageStyle() {
      // console.log('this is getUserMessageStyle' + this.style.userTextColor)
      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.userBackgroundColor,
          color: this.style.userTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.userBackgroundColor,
          color: this.localstyle.userTextColor,
        }
      }
    },
    getBotMessageStyle() {
      // console.log('this is getBotMessageStyle' + this.style.botTextColor)

      if (this.bot_id == 'preview') {
        return {
          backgroundColor: this.style.botBackgroundColor,
          color: this.style.botTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.botBackgroundColor,
          color: this.localstyle.botTextColor,
        }
      }
    },
  },
  methods: {
    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }
      text = text.trim()
      text = text.replaceAll('\\n', '<br>')
      // text = text + '<br/>' + text
      // console.log('inside markdown', text)
      // let cursorAdded = false
      // workaround for incomplete code, closing the block if it's not closed
      // First, count occurrences of "```" in the text
      const codeBlockCount = (text.match(/```/g) || []).length
      const shouldAddClosingBlock =
        codeBlockCount % 2 === 1 && !text.endsWith('```')
      // If the count is odd and the text doesn't end with "```", add a closing block
      if (shouldAddClosingBlock) {
        text += '█\n```'
        // cursorAdded = true
      }
      if (codeBlockCount && !shouldAddClosingBlock) {
        // make sure the last "```" is on a newline
        text = text.replace(/```$/, '\n```')
      }
      // if (!cursorAdded) {
      //   text += '█'
      // }

      try {
        // convert to markdown
        let parsed = marked.parse(text)
        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return text
      }
    },
    async startConversation() {
      try {
        const response = await HTTPService.get_conversation(this.conversationId)
        // console.log(response.data)
        for (const message of response.data) {
          const text = message.text
          const is_user = message.is_user
          this.messages.push({ text, is_user })
          // this.addMessage(message.text, message.is_user)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async sendMessage() {
      if (!this.userInput) return
      this.addMessage(this.userInput, true)
      this.messageIndex = this.messageIndex + 1
      const eventSource = new EventSource(
        `${process.env.VUE_APP_API_URL}/conversation?bot_id=${this.bot_id}&user_input=${this.userInput}&conversation_id=${this.conversationId}`,
      )
      //initialize the message
      this.addMessage('', false)
      eventSource.onmessage = (event) => {
        if (event.data === '[DONE]') {
          console.log('DONE')
          this.messageIndex = this.messageIndex + 1
          eventSource.close()
        } else {
          // console.log('start add "' + event.data + '"')
          // console.log(event.data)
          this.addMessage(event.data, false)
          // console.log('endadd add')
        }
      }

      this.userInput = ''
    },
    addMessage(text, is_user) {
      if (this.messageIndex >= 0 && is_user === false) {
        // if first message then just push
        if (!this.messages[this.messageIndex]) {
          this.messages.push({
            text: '',
            is_user: is_user,
          })
        } else {
          // console.log('adding "' + text + '"')

          this.messages[this.messageIndex].text += text
          this.messages[this.messageIndex].is_user = is_user
        }
      } else {
        this.messageIndex = this.messages.length

        this.messages.push({
          text,
          is_user,
        })
        this.messageIndex = this.messages.length - 1
      }

      // Scroll to the bottom of the chatbot conversation
      this.$refs.chatbotConversation.scrollTop =
        this.$refs.chatbotConversation.scrollHeight
    },
    endConversation() {
      // this.$router.push('/chatbot-dashboard')
    },
  },
}
</script>

<style scoped>
.chatbot {
  max-width: 600px;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.chatbot-conversation {
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
  overflow: auto;
}

.chatbot-message {
  margin: 10px 0;
}

.user-message {
  text-align: right;
}

.bot-message {
  text-align: left;
}

.chatbot-message div {
  display: inline-block;
  padding: 1.25rem;
  border-radius: 5px;
  max-width: 80%;
}

.chatbot-message div:last-child {
  margin-bottom: 0;
}

.chatbot-message.user-message div {
  color: #fff;
}

.chatbot-message.bot-message div {
  color: #fff;
}

.chatbot-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

input[type='text'] {
  flex: 1;
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  line-height: normal;
}

button {
  padding: 12px 17px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

button:hover {
  cursor: pointer;
  background-color: #0089b9;
}

.chatbot-header {
  display: flex;
  flex-direction: row;
  background-color: rgb(173, 92, 92);
  width: 100%;
  /* height: 70px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 10px;
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.chatbot-heading {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
}

p {
  margin-bottom: 0px;
  font-size: 16px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.centered-image {
  max-width: 40px;
  max-height: 40px;
}
</style>
